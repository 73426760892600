import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Grid, GridCol } from '@naf/grid';
import { Tab, TabList, TabPanel, Tabs } from '@naf/tabs';
import { Text, TextVariant } from '@naf/text';
import { breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { useHistory } from 'react-router';
import BlockContent from '../../block-content/BlockContent';
import useSelector from '../../../redux/typedHooks';
import RelatedArticleList from '../../related-articles/RelatedArticleList';
import { CarTestCard } from '../carTest/CarTestCard';
import { SafetyTest } from '../carTest/SafetyTest';
import { ElectricRecirculation } from './ElectricRecirculation';
import { CarModelType } from '../../../../../types/carModelType';
import { TDateISO } from '../../../../../types/DateType';

const aboutCarTabItem = 'about-car';
export const testTabItem = 'test';
const safetyTabItem = 'safety';
const relatedTabItem = 'related';

interface Props {
  slug: string;
}

const AboutCar = ({ slug }: Props) => {
  const stateData = useSelector((state) => state.cars.mappedData[slug]);
  if (stateData) {
    const { data, meta } = stateData;
    return <AboutCarMarkup car={data} meta={meta} />;
  }
  return null;
};

const AboutCarMarkup = ({
  car: { nafChargeTests, nafRangeTests, motorTests, nafArticleTest, safetyTest, fuelCategory, meta },
}: {
  car: CarModelType;
  meta: { isUpdating: boolean; fetchedAt?: TDateISO; isLoggedin?: boolean };
}) => {
  const history = useHistory();
  const tests = [
    ...(nafChargeTests || []),
    ...(nafRangeTests || []),
    ...(motorTests || []),
    ...(nafArticleTest || []),
  ].sort((e1, e2) => e2.meta?.publishAt.localeCompare(e1.meta?.publishAt));

  const hasTest = (tests && tests.length > 0 && tests.some((e) => !!e)) || false;

  const hasAboutCarContent = !!meta;
  const hasRelatedContent = meta?.relatedContent && meta?.relatedContent.length > 0;

  const isElectric = fuelCategory?.includes('Elektrisk');

  const defaultSelectedTab = useMemo(() => (hasAboutCarContent ? aboutCarTabItem : testTabItem), [hasAboutCarContent]);

  const tabFromUrl = new URLSearchParams(history.location.search).get('tab') || '';
  const tabs = [];
  if (hasTest) tabs.push(testTabItem);
  if (safetyTest) tabs.push(safetyTabItem);
  if (hasRelatedContent) tabs.push(relatedTabItem);
  const selectedTab = tabs.includes(tabFromUrl) ? tabFromUrl : defaultSelectedTab;
  return (
    <Wrapper>
      {hasTest || hasRelatedContent ? (
        <Tabs selectedTab={selectedTab} maxWidth={736}>
          <TabList>
            {meta && <Tab item={aboutCarTabItem}>Om bilen</Tab>}
            {hasTest && <Tab item={testTabItem}>Tester</Tab>}
            {safetyTest && <Tab item={safetyTabItem}>Bilens sikkerhet</Tab>}
            {hasRelatedContent && <Tab item={relatedTabItem}>Tips og råd</Tab>}
          </TabList>
          {hasAboutCarContent && (
            <TabPanel item={aboutCarTabItem}>
              <TabContent>
                {meta && meta.ingress && <SanityIngress variant={TextVariant.Ingress}>{meta.ingress}</SanityIngress>}
                {isElectric && <ElectricRecirculation />}
                <BodyWrapper>{meta && meta.body && <BlockContent value={meta.body} />}</BodyWrapper>
              </TabContent>
            </TabPanel>
          )}
          {hasTest && (
            <TabPanel item={testTabItem}>
              <TabContent>
                <TestList>
                  {tests.map((test) => (
                    <CarTestCard key={test.id} carTest={test} />
                  ))}
                </TestList>
              </TabContent>
            </TabPanel>
          )}
          {safetyTest && (
            <TabPanel item={safetyTabItem}>
              <TabContent>
                <SafetyTest safetyTest={safetyTest} />
              </TabContent>
            </TabPanel>
          )}
          {hasRelatedContent && (
            <TabPanel item={relatedTabItem}>
              <RelatedArticleList relatedContent={meta?.relatedContent} />
            </TabPanel>
          )}
        </Tabs>
      ) : (
        meta &&
        (meta.body?.length > 0 || meta.ingress) && (
          <Grid columns={8}>
            <GridCol s="8" m="8" l="6" xl="6">
              <Text tag="h2" variant={TextVariant.Header2}>
                Om bilen
              </Text>
              {meta && meta.ingress && <SanityIngress variant={TextVariant.Ingress}>{meta.ingress}</SanityIngress>}
              {isElectric && <ElectricRecirculation />}
              {meta && meta.body && <BlockContent value={meta.body} />}
            </GridCol>
          </Grid>
        )
      )}
    </Wrapper>
  );
};

const TabContent = styled.div`
  margin-top: 32px;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.l}) {
    margin-top: 16px;
  }

  a {
    cursor: pointer;
    color: ${({ theme }) => (theme?.typography?.textLink ? theme.typography.textLink : `#1a1a1a`)};
    text-decoration: underline;
    &:hover {
      color: ${nafColor.primary.park};
      text-decoration: none;
    }
  }
`;

const SanityIngress = styled(Text)`
  margin-top: 0;
`;

const BodyWrapper = styled.div`
  max-width: 544px;

  p {
    a:hover {
      color: ${nafColor.primary.park};
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 16px;

  @media (max-width: ${breakpoints.s}) {
    margin-top: 0;
  }
`;

const TestList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export default AboutCar;
