import { Text, TextVariant } from '@naf/text';
import { DateRange } from '@styled-icons/material/DateRange';
import React from 'react';
import { createNorwegianDateString } from '../../../utils/createNorwegianDateString';
import useSelector from '../../../redux/typedHooks';
import { imageUrlForMotor } from '../../../utils/imageUrl';
import { LazyPicture } from '../../lazy-picture/LazyPicture';
import MotorIcon from '../../icons/Motor';
import { MotorTestProps } from './CarTestCard';
import S from './Styles';

export const MotorTestCard = ({ test: { name, score, meta, image, ingress, url } }: { test: MotorTestProps }) => {
  const dateCreated = new Date(meta.publishAt);
  const norwegianDateCreated = createNorwegianDateString(dateCreated);
  const application = useSelector((state) => state.application);

  const mainImageSrcSet = image && {
    webp:
      (!!image &&
        !!imageUrlForMotor(image, application) &&
        imageUrlForMotor(image, application)?.withOptions({ format: 'webp' }).url()) ||
      undefined,
    jpeg:
      (!!imageUrlForMotor(image, application) && imageUrlForMotor(image, application)?.withOptions({}).url()) ||
      undefined,
  };
  return (
    <S.Wrapper>
      <S.ExternalLinkListElement href={url} target="_blank">
        <S.ElementImage>
          <LazyPicture srcSet={mainImageSrcSet} displayName={name} />
        </S.ElementImage>
        <S.ElementInfo>
          <Text tag="h3" variant={TextVariant.Header3}>
            {name}
          </Text>
          <S.ElementSubInfo>{ingress}</S.ElementSubInfo>
          <S.MotorIconWrapper>
            <S.IconSet>
              <DateRange size={18} />
              {norwegianDateCreated}
            </S.IconSet>
            <S.IconSet>
              <MotorIcon size={18} />
              {`${score}/100 av Motor`}
            </S.IconSet>
          </S.MotorIconWrapper>
        </S.ElementInfo>
      </S.ExternalLinkListElement>
    </S.Wrapper>
  );
};
