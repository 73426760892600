import React, { useState, useCallback } from 'react';
import { CameraAlt } from '@styled-icons/material-outlined/CameraAlt';
import S from '../../styles/bilguide/StyledBilGuide';

interface LazyPictureProps {
  displayName: string;
  srcSet: {
    webp?: string | null;
    jpeg?: string | null;
  } | null;
  altText?: string;
}

export const LazyPicture = ({ srcSet, displayName, altText }: LazyPictureProps) => {
  const [isPictureLoaded, setPictureLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const pictureRef = useCallback((pictureNode) => {
    if (pictureNode) {
      const picture: HTMLPictureElement = pictureNode;
      picture.onload = () => setPictureLoaded(true);
      picture.onerror = () => setHasError(true);
      return () => {
        picture.onload = () => null;
        picture.onerror = () => null;
      };
    }
    return () => {};
  }, []);

  if (hasError || (!srcSet?.webp && !srcSet?.jpeg)) {
    return (
      <S.ImagePlaceholder width={220} height={166}>
        <CameraAlt color="inherit" size={32} />
        <S.PlaceholderTextElement>Bilde kommer snart!</S.PlaceholderTextElement>
      </S.ImagePlaceholder>
    );
  }
  return isPictureLoaded ? (
    <S.ImagePlaceholder width={220} height={166} />
  ) : (
    <picture ref={pictureRef}>
      {!!srcSet && srcSet.webp && <source srcSet={srcSet.webp} type="image/webp" />}
      {!!srcSet && srcSet.jpeg && (
        <>
          <source srcSet={srcSet.jpeg} type="image/jpeg" />
          <img ref={pictureRef} src={srcSet.jpeg} alt={altText || displayName} />
        </>
      )}
    </picture>
  );
};
