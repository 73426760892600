import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CarChargingIllustration } from './CarChargingIllustration';

const ElectricRecirculation = () => (
  <UnstyledLink to="/elbil">
    <Box>
      <Image />
      <BodyText variant={TextVariant.Header2}>På jakt etter din første elbil?</BodyText>
      <Button>
        <ButtonText variant={TextVariant.Button}>Alt om elbil</ButtonText>
        <Icon />
      </Button>
    </Box>
  </UnstyledLink>
);

const UnstyledLink = styled(Link)`
  text-decoration: none !important;
  margin: ${spacing.space32} 0;
  display: block;
`;

const Box = styled.div`
  position: relative;
  padding: ${spacing.space16} ${spacing.space24};
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${spacing.space24};
  align-items: center;

  background-color: ${({ theme }) =>
    theme?.componentColors?.cta?.secondary || themeLight.componentColors.cta.secondary};
  color: ${({ theme }) => theme?.typography?.defaultText || themeLight.typography.defaultText};

  @media (max-width: ${breakpoints.s}) {
    flex-flow: column nowrap;
    align-items: stretch;
    row-gap: ${spacing.space24};
    padding: ${spacing.space24};

    margin-top: calc(160px / 2);
  }
`;

const Image = styled(CarChargingIllustration)`
  width: 80px;
  height: 80px;

  flex-shrink: 0;

  @media (max-width: ${breakpoints.s}) {
    width: 160px;
    height: 160px;

    align-self: center;
    margin-top: calc(-160px / 2 - ${spacing.space24});
    margin-bottom: -40px;
  }
`;

const BodyText = styled(Text)`
  //font-size: 20px;
  //font-height: 28px;

  flex-shrink: 1;
  flex-grow: 1;

  margin: 0;

  @media (max-width: ${breakpoints.s}) {
    text-align: center;
  }
`;

const ButtonText = styled(Text)``;

const Button = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${spacing.space12};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  background-color: ${({ theme }) => theme?.background?.brandInverted || themeLight.background.brandInverted};
  color: ${({ theme }) => theme?.typography?.brandTextInverted || themeLight.typography.brandTextInverted};

  @media (max-width: ${breakpoints.s}) {
    height: 56px;
    width: initial;
    border-radius: 2px;
  }

  @media (min-width: calc(${breakpoints.s} + 1px)) {
    width: 40px;
    height: 40px;
    border-radius: 20px;

    ${ButtonText} {
      display: none;
    }
  }
`;

const Icon = styled(ArrowForward)`
  width: 24px;
  height: 24px;

  @media (max-width: ${breakpoints.s}) {
    width: 16px;
    height: 16px;
  }
`;

export { ElectricRecirculation };
