import React from 'react';
import { ChargeTestType, MotorTestType, RangeTestType, TestTypeLabel } from '../../../../../types/testType';
import { ArticleType } from '../../../../../types/articleType';
import { MotorTestCard } from './MotorTestCard';
import { ChargeTestCard } from './ChargeTestCard';
import { RangeTestCard } from './RangeTestCard';
import { ArticleTestCard } from './ArticleTestCard';
import { MotorListItemImage } from '../../../utils/imageUrl';

export interface MotorTestProps {
  id: MotorTestType['id'];
  name: MotorTestType['name'];
  score: MotorTestType['score'];
  meta: MotorTestType['meta'];
  image: MotorListItemImage;
  ingress: MotorTestType['naf']['intro'];
  url: MotorTestType['naf']['url'];
  type: MotorTestType['type'];
}

interface Props {
  carTest: ChargeTestType | RangeTestType | MotorTestProps | ArticleType;
}

export const CarTestCard = ({
  carTest: {
    meta: { contentType },
  },
  carTest,
}: Props) => {
  if (contentType === TestTypeLabel.motorVehicleTest) {
    return <MotorTestCard test={carTest as MotorTestProps} />;
  }
  if (contentType === TestTypeLabel.nafnoArticleCharge) {
    return <ChargeTestCard test={carTest as ChargeTestType} />;
  }
  if (contentType === TestTypeLabel.nafnoArticleRange) {
    return <RangeTestCard test={carTest as RangeTestType} />;
  }
  if (contentType === TestTypeLabel.nafnoArticle) {
    return <ArticleTestCard testItem={carTest as ArticleType} />;
  }
  return null;
};
