import React, { useMemo } from 'react';
import { Text, TextVariant } from '@naf/text';
import { WbSunny } from '@styled-icons/material-outlined/WbSunny';
import { AcUnit } from '@styled-icons/material-outlined/AcUnit';
import { RangeTestType } from '../../../../../types/testType';
import useSelector from '../../../redux/typedHooks';
import { getSeason, isFuture } from '../../../utils/Dates';
import { cloudinaryImage } from '../../../utils/imageUrl';
import { LazyImage } from '../../lazy-image/LazyImage';
import RoadIcon from '../../icons/Road';
import S from './Styles';

export const RangeTestCard = ({
  test: { name, ingress, slug, image, summer_km, winter_km, summerTestDate, winterTestDate, statedRange_km, vehicle },
}: {
  test: RangeTestType;
}) => {
  const carSlug = vehicle?.slug;
  const application = useSelector((state) => state.application);

  const seasonWinter = useMemo(() => winterTestDate && getSeason(winterTestDate), [winterTestDate]);
  const seasonSummer = useMemo(() => summerTestDate && getSeason(summerTestDate), [summerTestDate]);

  const isFutureWinterTest = useMemo(() => winterTestDate && isFuture(winterTestDate), [winterTestDate]);
  const isFutureSummerTest = useMemo(() => summerTestDate && isFuture(summerTestDate), [summerTestDate]);

  const mainImageSrc = image && image.publicId && cloudinaryImage(image.publicId, application, 250);

  return carSlug && slug && slug ? (
    <S.Wrapper>
      <S.ListElement to={`/bilguiden/bilmodell/${carSlug}/${slug}`}>
        <S.ElementImage>
          <LazyImage actualSrc={mainImageSrc} altText={image?.altText} displayName={name} />
        </S.ElementImage>
        <S.ElementInfo>
          <Text tag="h3" variant={TextVariant.Header3}>
            {name}
          </Text>
          {ingress && <S.ElementSubInfo>{ingress}</S.ElementSubInfo>}
          <S.IconWrapper>
            {!!statedRange_km && (
              <S.IconSet>
                <RoadIcon size={18} />
                <span>
                  <b>{statedRange_km} km</b> rekkevidde for testbil* (WLTP){' '}
                </span>
              </S.IconSet>
            )}
            {summerTestDate && (
              <S.IconSet>
                <WbSunny size={18} />
                <span>
                  {isFutureSummerTest ? (
                    `Testresultat kommer ${seasonSummer}`
                  ) : (
                    <>
                      <b>{summer_km} km</b> rekkevidde målt {seasonSummer}
                    </>
                  )}
                </span>
              </S.IconSet>
            )}
            {winterTestDate && (
              <S.IconSet>
                <AcUnit size={18} />
                <span>
                  {isFutureWinterTest ? (
                    `Testresultat kommer ${seasonWinter}`
                  ) : (
                    <>
                      <b>{winter_km} km</b> rekkevidde målt {seasonWinter}
                    </>
                  )}
                </span>
              </S.IconSet>
            )}
          </S.IconWrapper>
        </S.ElementInfo>
      </S.ListElement>
    </S.Wrapper>
  ) : null;
};
