import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CameraAlt } from '@styled-icons/material-outlined/CameraAlt';
import styled from 'styled-components';
import { Transition, TransitionGroup } from 'react-transition-group';
import S from '../../styles/bilguide/StyledBilGuide';
import { forceHttps } from '../../lib/forceHttps';
import { AnimationWrapper } from '../skeleton-loaders/AnimationWrapper';

interface LazyImageProps {
  displayName: string;
  actualSrc?: string;
  altText?: string;
}

export const LazyImage = ({ actualSrc, displayName, altText }: LazyImageProps) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  const imageComponentRef = useCallback((node) => {
    setImage(node);
  }, []);

  const imageRef = useRef<HTMLImageElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (typeof actualSrc === 'undefined') {
      setImageLoaded(false);
      setHasError(true);
    }
  }, [imageUrl, actualSrc]);

  useEffect(() => {
    const img = new Image();
    if (img) {
      img.onload = () => setImageLoaded(true);
      img.onerror = () => {
        setImageLoaded(true);
        setHasError(true);
      };
      if (typeof imageUrl !== 'undefined' && imageUrl) img.src = imageUrl || '';
      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }
    return () => {};
  }, [imageUrl]);

  useEffect(() => {
    if (IntersectionObserver) {
      const observer = new IntersectionObserver((entries) =>
        entries.forEach(
          (entry) => {
            if (entry.isIntersecting) {
              setImageUrl(forceHttps(actualSrc));
            }
          },
          { rootMargin: '0px 0px 200px 0px' },
        ),
      );
      if (image) {
        observer.observe(image);
        return () => observer.disconnect();
      }
    } else {
      setImageUrl(forceHttps(actualSrc));
    }
    return () => {};
  }, [image, actualSrc]);

  return (
    <StyledWrapper ref={imageComponentRef}>
      {!hasError && (
        <StyledTransitionGroup>
          {!isImageLoaded && (
            <Transition nodeRef={placeholderRef} in={!isImageLoaded} timeout={250} unmountOnExit mountOnEnter>
              {(state) => (
                <AnimationWrapper ref={placeholderRef} $state={state}>
                  <S.ImagePlaceholder />
                </AnimationWrapper>
              )}
            </Transition>
          )}
          {isImageLoaded && (
            <Transition nodeRef={imageRef} in={isImageLoaded} timeout={250} unmountOnExit mountOnEnter>
              {(state) => (
                <AnimationWrapper ref={imageRef} $state={state}>
                  <StyledImage src={imageUrl || undefined} alt={altText || displayName} />
                </AnimationWrapper>
              )}
            </Transition>
          )}
        </StyledTransitionGroup>
      )}
      {hasError && (
        <S.ImagePlaceholder>
          <CameraAlt color="inherit" size={32} />
          <S.PlaceholderTextElement>Bilde kommer snart!</S.PlaceholderTextElement>
        </S.ImagePlaceholder>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledTransitionGroup = styled(TransitionGroup)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
`;
