import React from 'react';

type CarChargingIllustrationProps = {
  className?: string;
};

const CarChargingIllustration = ({ className }: CarChargingIllustrationProps) => (
  <svg className={className} viewBox="0 0 164 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.2861 46.2852H47.7861C49.443 46.2852 50.7861 47.6283 50.7861 49.2852V57.7852C50.7861 59.442 52.1293 60.7852 53.7861 60.7852H62.2861"
      stroke="black"
    />
    <path
      d="M43.7521 53.5508H33.3525V38.3061H43.7521C44.0701 38.3061 44.3751 38.4326 44.6 38.6576C44.8248 38.8827 44.9512 39.188 44.9512 39.5063V52.3485C44.9515 52.5063 44.9206 52.6625 44.8605 52.8084C44.8004 52.9543 44.7121 53.0869 44.6007 53.1985C44.4894 53.3102 44.3571 53.3988 44.2115 53.4592C44.0658 53.5197 43.9098 53.5508 43.7521 53.5508Z"
      fill="#D3EAC2"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.4246 67.2268C78.4246 62.8472 74.8777 59.2969 70.5024 59.2969C66.127 59.2969 62.5801 62.8472 62.5801 67.2268V79.2288C62.5801 83.6083 66.127 87.1587 70.5024 87.1587C74.8777 87.1587 78.4246 83.6083 78.4246 79.2288V67.2268Z"
      fill="#1A1A1A"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M157.647 68.0822C157.647 63.7027 154.1 60.1523 149.725 60.1523C145.35 60.1523 141.803 63.7027 141.803 68.0822V80.0842C141.803 84.4638 145.35 88.0141 149.725 88.0141C154.1 88.0141 157.647 84.4638 157.647 80.0842V68.0822Z"
      fill="#1A1A1A"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M146.592 36.7511L152.49 34.3935C155.274 33.2855 156.756 29.9164 156.165 26.095C155.724 23.2381 153.186 21.1913 151.114 22.0229L142.146 25.6042L146.592 36.7511Z"
      fill="#D3EAC2"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.0543 36.7511L67.1555 34.3935C64.372 33.2855 62.8903 29.9164 63.4812 26.095C63.9223 23.2381 66.4596 21.1913 68.5322 22.0229L77.4994 25.6042L73.0543 36.7511Z"
      fill="#D3EAC2"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M162.572 53.2216V62.0087C162.572 68.2048 157.996 73.2285 152.352 73.2285H67.3675C61.7234 73.2285 57.1456 68.2048 57.1456 62.0087V53.3352C57.1323 49.3239 58.3261 45.4015 60.5715 42.079L63.0081 38.5063L64.6654 36.0801C65.5163 34.8315 66.2177 33.487 66.7551 32.0745L74.2235 12.5112C76.885 5.53718 83.0986 1 89.9846 1H129.936C136.822 1 143.038 5.53718 145.697 12.5112L153.202 32.1688C153.717 33.5207 154.382 34.8105 155.185 36.0137L156.851 38.5063L159.266 42.1261C161.437 45.4175 162.587 49.2777 162.572 53.2216V53.2216Z"
      fill="#D3EAC2"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M142.301 30.5561H77.5995C76.5289 30.5561 75.8031 29.358 76.2163 28.2736L80.747 16.4066C82.9203 10.7078 88.0013 7 93.6261 7H126.274C131.901 7 136.98 10.7078 139.154 16.4066L143.684 28.2736C144.097 29.358 143.372 30.5561 142.301 30.5561Z"
      fill="#E3EBFC"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M129.677 54.5781H89.9077C88.2959 54.5781 86.9893 55.886 86.9893 57.4993V62.8016C86.9893 64.415 88.2959 65.7228 89.9077 65.7228H129.677C131.289 65.7228 132.596 64.415 132.596 62.8016V57.4993C132.596 55.886 131.289 54.5781 129.677 54.5781Z"
      fill="#E3EBFC"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M148.334 41.5078H143.623C140.49 41.5078 137.949 44.0506 137.949 47.1873C137.949 50.324 140.49 52.8669 143.623 52.8669H148.334C151.468 52.8669 154.008 50.324 154.008 47.1873C154.008 44.0506 151.468 41.5078 148.334 41.5078Z"
      fill="#FFDB16"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.0409 41.293H74.3303C71.1966 41.293 68.6562 43.8358 68.6562 46.9725C68.6562 50.1092 71.1966 52.652 74.3303 52.652H79.0409C82.1746 52.652 84.7149 50.1092 84.7149 46.9725C84.7149 43.8358 82.1746 41.293 79.0409 41.293Z"
      fill="#FFDB16"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7742 85.6562H0.999535L0.999535 8.68693C0.999535 8.34986 1.1333 8.0266 1.37141 7.78826C1.60953 7.54991 1.93248 7.41602 2.26923 7.41602H32.5067C32.8434 7.41602 33.1664 7.54991 33.4045 7.78826C33.6426 8.0266 33.7764 8.34986 33.7764 8.68693L33.7764 85.6562H33.7742Z"
      fill="#E3EBFC"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3143 85.6562H6.53957L6.53957 8.74051C6.53957 8.40344 6.67333 8.08018 6.91145 7.84183C7.14957 7.60349 7.47251 7.4696 7.80926 7.4696H38.0467C38.3834 7.4696 38.7064 7.60349 38.9445 7.84183C39.1826 8.08018 39.3164 8.40344 39.3164 8.74051L39.3164 85.6562H39.3143Z"
      fill="#E3EBFC"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9254 60.0398C30.6674 60.0398 36.9436 53.7576 36.9436 46.0082C36.9436 38.2587 30.6674 31.9766 22.9254 31.9766C15.1834 31.9766 8.90723 38.2587 8.90723 46.0082C8.90723 53.7576 15.1834 60.0398 22.9254 60.0398Z"
      fill="#D3EAC2"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1905 35.8828L17.6514 46.6032L22.3448 46.0074L19.9081 56.1341L28.1987 43.0519L23.4817 43.3626L24.1905 35.8828Z"
      fill="#FFDB16"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { CarChargingIllustration };
