import { Text, TextVariant } from '@naf/text';
import { DateRange } from '@styled-icons/material/DateRange';
import React from 'react';
import { ArticleType } from '../../../../../types/articleType';
import { createNorwegianDateString } from '../../../utils/createNorwegianDateString';
import useSelector from '../../../redux/typedHooks';
import { cloudinaryImage } from '../../../utils/imageUrl';
import { LazyImage } from '../../lazy-image/LazyImage';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import S from './Styles';

export const ArticleTestCard = ({ testItem }: { testItem: ArticleType }) => {
  const { image, ingress, meta, name } = testItem;
  const dateCreated = new Date(meta.publishAt);
  const norwegianDateCreated = createNorwegianDateString(dateCreated);
  const application = useSelector((state) => state.application);

  const mainImageSrc = (!!image && image.publicId && cloudinaryImage(image.publicId, application, 250)) || undefined;
  const url = useDocumentUrlWithNode(testItem) || '';
  return (
    <S.Wrapper>
      <S.ListElement to={url}>
        <S.ElementImage>
          <LazyImage actualSrc={mainImageSrc} altText={image?.altText} displayName={name} />
        </S.ElementImage>
        <S.ElementInfo>
          <Text tag="h3" variant={TextVariant.Header3}>
            {name}
          </Text>
          <S.ElementSubInfo>{ingress}</S.ElementSubInfo>
          <S.MotorIconWrapper>
            <S.IconSet>
              <DateRange size={18} />
              {norwegianDateCreated}
            </S.IconSet>
          </S.MotorIconWrapper>
        </S.ElementInfo>
      </S.ListElement>
    </S.Wrapper>
  );
};
